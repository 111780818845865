import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Thinking of the Tuscany, a picture comes into my mind of stone houses with
long driveways flanked by cypresses, mellow hills, vineyards, wide fields,
the mediterranean sea. And of course the many stories of my friends about the
great mountain biking.`}</p>
    <br />
    <p>{`The alps are covered by snow, so we go further south. About 1,5h south of
Pisa and 3h north of Rome, there are several spots close together, making
it a versatile destination for mountain biking. Zoom and move the map below,
click on the trails to get further information or download the gpx tracks.`}</p>
    <br />
    <p>{`You can also open the region directly in the
`}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=10,42.9634,10.7306&map=cyclemap"
      }}>{`web-app`}</a>{`
and use it for navigation.`}</p>
    <TrailguidePlugin content="lat=42.9539&lng=10.7209" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=11,42.9539,10.7209"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Le Croste`}</h4>
    <p>{`The first spot we look at is Piombino. At the hills north of the town, you
find a little easier trails and can ride all the way down to the sea.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/4238"
      }}>{`trailguide.net/4238`}</a></p>
    <Image src="destinations/maremma_5.jpg" mdxType="Image" />
    <br />
    <p>{`The descents often end at the mediterranean sea. Don't worry, this is just an
extra ending of a really flowy trail.`}</p>
    <Image src="destinations/maremma_6.jpg" mdxType="Image" />
    <br />
    <h4>{`Soletta`}</h4>
    <p>{`Several hills offer loads of trails. You have to earn all by pedaling, but
there are some really nice uphill trails which make climibing a lot more fun.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/4237"
      }}>{`trailguide.net/4237`}</a>{`.`}</p>
    <Image src="destinations/maremma_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Rest`}</h4>
    <p>{`With all the nice riding, don't forget about the tasty Italian food. The
castle of Populonia is a great place for a break. Take this trail to get
there: `}<a parentName="p" {...{
        "href": "https://trailguide.net/4239"
      }}>{`trailguide.net/4239`}</a>{`.`}</p>
    <Image src="destinations/maremma_9.jpg" mdxType="Image" />
    <br />
    <h4>{`Punta Ala Trail Center`}</h4>
    <p>{`Spot number two is very versatile. In the hills between Casiglione Della
Pescaia and Follonica the very first Enduro World Series took place, and
thus fits the character of the trails. More technical, rocky, sometimes very
loose. Good gravel roads allow an easy access and in the area around Tirli
you can also take a shuttle.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=13,42.8792,10.8347"
      }}>{`Punta Ala Trail Center`}</a>{`.`}</p>
    <Image src="destinations/maremma_19.jpg" mdxType="Image" />
    <br />
    <p>{`Towards the coast, the trails get easier and the hill next to Puntone
offers a few fun and flowy ones for less experienced riders. You can even
ride down all the way to the sea.`}</p>
    <Image src="destinations/maremma_20.jpg" mdxType="Image" />
    <br />
    <h4>{`Massa Marittima`}</h4>
    <p>{`Massa Marittima is further inland and spot number three in a radius of 20km.
The beautiful historic village sits at a hillside above the valley.`}</p>
    <Image src="destinations/maremma_13.jpg" mdxType="Image" />
    <br />
    <h4>{`Canyon trail`}</h4>
    <p>{`From here you immediately reach the easier XC trails on the south side of the
village with unique trails like the Canyon trail.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/4247"
      }}>{`trailguide.net/4247`}</a></p>
    <Image src="destinations/maremma_17.jpg" mdxType="Image" />
    <br />
    <p>{`The path winds through a little canyon, overgrown by trees. It is easy to ride
and has many nice corners.`}</p>
    <Image src="destinations/maremma_16.jpg" mdxType="Image" />
    <br />
    <h4>{`Freeride Il Cicalino`}</h4>
    <p>{`North of Massa Marittima, 6 very well built, signed easy to access enduro
trails wind down in every direction of the Monte Arsenti.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/4243"
      }}>{`trailguide.net/4243`}</a>{`.`}</p>
    <Image src="destinations/maremma_11.jpg" mdxType="Image" />
    <br />
    <p>{`The mountain offers descents up to 400 vertical metres and trails from fast
and easy to rocky and technical.`}</p>
    <Image src="destinations/maremma_12.jpg" mdxType="Image" />
    <br />
    <h4>{`Bonatto`}</h4>
    <p>{`The trail network is developed and maintained by the Trail brothers, a local
group of trail builders. You can clearly see this in the high quality of the trails.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/1426"
      }}>{`trailguide.net/1426`}</a>{`.`}</p>
    <Image src="destinations/maremma_10.jpg" mdxType="Image" />
    <br />
    <h4>{`Massa Marittima`}</h4>
    <p>{`Ice skating in front of the Cattedrale di San Cerbone.`}</p>
    <Image src="destinations/maremma_15.jpg" mdxType="Image" />
    <br />
    <h4>{`Italian street life`}</h4>
    <p>{`Although the Maremma region is a destination for the whole year, it can
get quite hot in summer. A good time to go there is from September to June.
You find all trails on our free
web-app `}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=10,42.9634,10.7306&map=cyclemap"
      }}>{`trailguide.net`}</a>{`.
Open Trailguide on the smartphone and use it for navigation. Or download
the GPX tracks for offline use or your gps device.`}</p>
    <Image src="destinations/maremma_14.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      